import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Navigation from './components/navigation.js';

import React from 'react';

function App() {
  return (

		<div className="App">
			<Navigation/>
		</div>
  );
}

export default App;
