import '../App.css';
import React, { Component } from 'react'

export default class About extends Component{
	render(){
	return(
		
		<div class = "content">

		</div>
	);
	}
}

