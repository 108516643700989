import '../App.css';
import React, { Component } from 'react'

export default class Resume extends Component{
    render(){
        return(

            <div class = "content">
            </div>
        );
    }
}