import '../App.css';
import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import {Route, Switch, Link } from 'react-router-dom';

import Home from '../routes/home.js';
import About from '../routes/about.js';
import Resume from '../routes/resume.js';



export default class Navigation extends Component{
	render(){
	return( 
		<div>	
			<div>
				<Navbar collapseOnSelect fixed='top' expand='sm' bg='dark' variant='dark'>
					<Navbar.Toggle aria-controls='responsive-navbar-nav' />
						<Navbar.Collapse id='responsive-navbar-nav'>
						<Nav>
							<Nav.Link as={Link} to="/home">Home</Nav.Link>
							<Nav.Link as={Link} to="/about">About</Nav.Link>
							<Nav.Link as={Link} to="/resume">Resume</Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Navbar>
			</div>
			<div>
				<Switch>
					<Route path="/home" component={Home} />
					<Route path="/about" component={About} />
					<Route path="/resume" component={Resume} />
				  </Switch>
			</div>
		</div>
			
	);
		
	}
}
